import * as React from 'react'
import Avatar from '@mui/material/Avatar'

function stringToColor(string: string) {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

function stringAvatar(name: string) {
  const names = name.toUpperCase().split(' ')
  const firstInitial = names[0][0]
  const secondInitial = names[1] ? names[1][0] : ''

  return {
    sx: {
      bgcolor: stringToColor(name),
      color: 'white'
    },
    children: `${firstInitial}${secondInitial}`
  }
}

export default function ColorAvatar(props: any) {
  return <Avatar {...stringAvatar((props as any).name.toUpperCase() as string)} />
}
