import React from 'react'
import { ChildrenProps } from '../types'
import { Box } from '@mui/material'

type Props = ChildrenProps & {
  columns?: number
}

/**
 * A multi column form which will stretch to fit the width.
 * At smaller breakpoints it will revert to single column but will still stretch.
 * The content for each column must be in a div.
 * @param props
 * @constructor
 */
export default function ResponsiveFormContainer(props: Props) {
  const cols = props.columns ? props.columns : 1
  return (
    <Box
      display="grid"
      sx={{
        gridTemplateColumns: {
          sm: `1fr`,
          md: `repeat(${cols}, 1fr)`
        },
        gap: {
          sm: 0,
          md: 6
        }
      }}>
      {props.children}
    </Box>
  )
}
