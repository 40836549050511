import * as React from 'react'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
import { ChangeEvent } from 'react'

export type PaginationProps = {
  page: number
  count: number
  handleChange: (event: ChangeEvent<HTMLInputElement>, value: number) => void
}

export default function BasicPagination(props: PaginationProps) {
  const [page, setPage] = React.useState(props.page || 1)
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    props.handleChange(event as ChangeEvent<HTMLInputElement>, value)
  }
  const count = props.count

  return (
    <Stack spacing={2}>
      <Pagination count={count} page={page} onChange={handleChange} />
    </Stack>
  )
}
