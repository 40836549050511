import React, { useEffect, useState } from 'react'
import { Tabs, Tab } from '@mui/material'

type Props = {
  tabLabels: string[]
  onTabChange: (newValue: number) => void
  defaultTabIndex?: number
}

const CustomTabs = (props: Props) => {
  const { tabLabels, onTabChange, defaultTabIndex } = props
  const [value, setValue] = useState(defaultTabIndex || 0)

  useEffect(() => {
    setValue(defaultTabIndex)
  }, [defaultTabIndex])

  const handleChange = (event, newValue: number) => {
    setValue(newValue)
    onTabChange(newValue)
  }
  return (
    <Tabs value={value} onChange={handleChange} aria-label="custom tabs">
      {tabLabels.map((label, index) => (
        <Tab disabled={label != 'Sent'} key={index} label={label} />
      ))}
    </Tabs>
  )
}

export default CustomTabs
