import React, { useState, useEffect } from 'react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { useGridApiContext } from '@mui/x-data-grid-premium'
import PropTypes from 'prop-types'

const CustomGridEditDateCell = ({ colDef, value, ...otherProps }) => {
  const [selectedDate, setSelectedDate] = useState(null)
  const apiRef = useGridApiContext()
  const { field, id } = otherProps

  useEffect(() => {
    if (value) {
      setSelectedDate(dayjs(value))
    } else {
      setSelectedDate(null)
    }
  }, [value])

  const handleChange = (date) => {
    setSelectedDate(date)
    apiRef.current.setEditCellValue({ id, field, value: date.toDate() })
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={selectedDate}
        onChange={(date) => handleChange(date)}
        format={'YYYY-MM-DD'}
        className="custom-date-picker"
        sx={{
          '& .MuiInputBase-input': {
            fontSize: '11px',
            minWidth: '60px'
          },
          '& .MuiIconButton-root': {
            marginLeft: '-27px'
          },
          '& .MuiInputAdornment-root': {
            marginRight: '0'
          }
        }}
        {...otherProps}
      />
    </LocalizationProvider>
  )
}

CustomGridEditDateCell.propTypes = {
  colDef: PropTypes.object.isRequired,
  onValueChange: PropTypes.func.isRequired,
  value: PropTypes.any
}

export default CustomGridEditDateCell
