import React from 'react'
import { Box } from '@mui/material'

type Props = {
  text: string
  icon: any
}
export function NoData(props: Props) {
  const Icon = props.icon
  return (
    <Box padding="50px" display={'flex'} alignItems={'center'} justifyContent={'center'} flexDirection={'column'}>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        sx={{
          borderRadius: '50%',
          width: '50px',
          height: '50px',
          backgroundColor: 'lightgray',
          color: 'white',
          marginBottom: '20px'
        }}>
        <Icon fontSize="large"></Icon>
      </Box>
      {props.text}
    </Box>
  )
}
