import React, { ChangeEvent } from 'react'
import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material'
import { useField } from 'formik'
import { ToolTip } from '../papertrail-styleguide'

export type FormFieldCheckboxProps = {
  name: string
  label: string | React.ReactElement
  disabled?: boolean
  noHelpText?: boolean
  onChange?: (event: ChangeEvent<HTMLInputElement>, value: boolean) => void
  checked?: boolean
  tooltip?: string
}

function FormFieldCheckbox(props: FormFieldCheckboxProps) {
  const { label, disabled, noHelpText, onChange, tooltip, ...rest } = props
  const [field, meta, fieldHelper] = useField(props.name)
  const hasError = meta.touched && meta.error

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.checked
    fieldHelper.setValue(value)
    if (onChange) {
      onChange(event, value) // Call the onChange prop with the updated value
    }
  }

  return (
    <FormControl error={!!hasError} disabled={disabled} fullWidth margin={'dense'}>
      <FormControlLabel
        sx={{ flexDirection: 'row', alignItems: 'flex-start' }}
        control={
          <ToolTip title={tooltip || ''}>
            <Checkbox
              {...field}
              checked={field.value}
              onChange={handleChange}
              disabled={disabled}
              disableRipple
              {...rest}
            />
          </ToolTip>
        }
        label={label}
      />
      {!noHelpText && <FormHelperText>{hasError && meta.error}</FormHelperText>}
    </FormControl>
  )
}

export default FormFieldCheckbox
