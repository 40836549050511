import React, { useEffect, useState } from 'react'
import { InputBase, InputAdornment, IconButton } from '@mui/material'
import { CloseIcon, SearchIcon } from '../icons'

type Props = {
  initialSearch: string
  search: (string) => void
  placeholder?: string
  styles?
  iconColor?: string
  historySearch?: string
}

/*
 * A generic search box to use in the blue header bar
 */
export default function SearchInput(props: Props) {
  const [searchText, setSearchText] = useState<string>(props.initialSearch)
  const { historySearch } = props
  const defaultStyles = {
    color: 'white',
    backgroundColor: '#2B3E56',
    borderBottom: 'none',
    paddingX: '8px',
    height: '40px',
    marginX: '8px'
  }

  function handleSearchChange(searchText) {
    setSearchText(searchText)
    props.search(searchText)
  }

  useEffect(() => {
    if (historySearch && historySearch.length > 0) {
      setSearchText(historySearch)
    }
  }, [historySearch])

  return (
    <InputBase
      sx={props.styles ? props.styles : defaultStyles}
      placeholder={props.placeholder ? props.placeholder : 'Search...'}
      data-testid="search-input"
      value={searchText}
      onChange={(e) => handleSearchChange(e.target.value)}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon fontSize={'medium'} sx={{ color: props.iconColor ? props.iconColor : 'white' }} />
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="Clear Search"
            onClick={() => handleSearchChange('')}
            edge="end"
            tabIndex={-1}
            sx={{ width: '31px' }}>
            {searchText === '' || searchText === null ? null : <CloseIcon fontSize={'medium'} />}
          </IconButton>
        </InputAdornment>
      }
    />
  )
}
