import React from 'react'
import { useField } from 'formik'
import { FormControl, FormHelperText, FormLabel, MenuItem, Select, Stack } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'

export type SelectOption = {
  value: string
  label: string
  disabled?: boolean
  icon?: any
  backgroundColour?: string
  fontColour?: string
  optionSx?: React.CSSProperties
  borderBottom?: boolean
  borderTop?: boolean
  borderBottomColor?: string // New property for borderBottom color
  borderTopColor?: string
}

type FormFieldSelectProps = {
  name: string
  label: string
  disabled?: boolean
  placeholder: string
  value?: string
  options: SelectOption[]
  mandatory?: boolean
  inline?: boolean
  fullWidth?: boolean
  icon?: any
  backgroundColour?: string
  fontColour?: string
  borderBottom?: boolean
  borderTop?: boolean
  borderBottomColor?: string // New property for borderBottom color
  borderTopColor?: string
  width?: string
  onChange?: (event: SelectChangeEvent<{ value: string }>) => void
}

export default function FormFieldSelectFormik(props: FormFieldSelectProps) {
  const {
    label,
    disabled,
    options,
    mandatory,
    placeholder,
    inline,
    fullWidth,
    backgroundColour,
    fontColour,
    onChange
  } = props
  const [field, meta, helpers] = useField(props.name)
  const name = field.name
  const hasError = meta.touched && meta.error

  const selectStyle = {
    width: props.width || (fullWidth ? '100%' : 'auto'),
    backgroundColor: backgroundColour || 'inherit',
    color: fontColour || 'inherit'
  }

  if (field.value === null || field.value === '') {
    helpers.setValue('null')
  }

  return (
    <FormControl variant="outlined" disabled={disabled} error={!!hasError} fullWidth margin={'dense'}>
      {!inline && (
        <FormLabel htmlFor={'fld-' + name}>
          {label}
          {mandatory && '*'}
        </FormLabel>
      )}
      <div>
        <Stack direction="row" alignItems={'center'} spacing={1}>
          <Select
            data-testid={'fld-' + name}
            id={'fld-' + name}
            name={name}
            {...field}
            sx={selectStyle}
            onChange={onChange}
            defaultValue={props.value || 'null'}>
            <MenuItem key="null" value="null" disabled>
              {placeholder}
            </MenuItem>
            {options.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                data-cy={option.label}
                style={{
                  backgroundColor: option.backgroundColour,
                  color: option.fontColour,
                  display: 'flex',
                  alignItems: 'center',
                  borderBottom: option.borderBottom ? `1px solid ${option.borderBottomColor}` : 'none',
                  borderTop: option.borderTop ? `1px solid ${option.borderTopColor}` : 'none'
                }}>
                {option.icon && (
                  <div
                    style={{
                      padding: '6px',
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                    {option.icon} <div style={{ padding: '6px' }}>{option.label}</div>
                  </div>
                )}
                {!option.icon && option.label}
              </MenuItem>
            ))}
          </Select>
          {inline && (
            <FormLabel htmlFor={'fld-' + name}>
              {label}
              {mandatory && '*'}
            </FormLabel>
          )}
        </Stack>
      </div>
      <FormHelperText>{hasError && meta.error}</FormHelperText>
    </FormControl>
  )
}
