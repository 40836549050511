import React, { useState, useEffect } from 'react'
import {
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Box,
  Typography,
  InputAdornment
} from '@mui/material'
import { Loading, SearchIcon } from '../papertrail-styleguide'

type Props = {
  apiResults: any
  onSelect
  onSearchTermChange
}

const ProductSearchAndSelect = (props: Props) => {
  const { apiResults, onSelect, onSearchTermChange } = props
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredData, setFilteredData] = useState([])

  const handleSearch = (event) => {
    const newSearchTerm = event.target.value
    setSearchTerm(newSearchTerm)

    onSearchTermChange(newSearchTerm)
  }

  const handleSelect = (selectedItem) => {
    onSelect(selectedItem)
    setFilteredData([])
    setSearchTerm('')
  }

  useEffect(() => {
    setFilteredData(apiResults.data)
  }, [apiResults.isLoaded])

  const findProductCode = (item) => {
    const field = item.fields.data.find((item) => item.key === 'product_code')
    const fieldValue = field && field.value ? field.value : ''
    return (
      <Box display={'flex'} flexDirection={'column'}>
        <Typography>{'Manufacturer: ' + item.manufacturer && item.manufacturer}</Typography>
        <Typography>{'Product Code: ' + fieldValue}</Typography>
      </Box>
    )
  }

  return (
    <Box>
      <TextField
        label=""
        variant="outlined"
        fullWidth
        value={searchTerm}
        placeholder={'Search - Name,Serial,Barcode,RFID...'}
        onChange={handleSearch}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={{ padding: '8px' }}>
              <SearchIcon />
            </InputAdornment>
          )
        }}
      />
      <Box style={{ padding: 16, maxHeight: '300px', overflowY: 'auto' }}>
        <List>
          {filteredData &&
            filteredData.length > 0 &&
            filteredData.map((item) => (
              <ListItem key={item.id} button onClick={() => handleSelect(item)}>
                <ListItemAvatar>
                  <Avatar alt={item.name} src={item.images[0]} />
                </ListItemAvatar>
                <ListItemText primary={item.name} secondary={findProductCode(item)} />
              </ListItem>
            ))}
          {apiResults.isLoaded && apiResults.data.length === 0 && <ListItem>No Products Found</ListItem>}
        </List>
        {apiResults.isLoading && <Loading />}
      </Box>
    </Box>
  )
}

export default ProductSearchAndSelect
