import { createTheme } from '@mui/material'
import { buttonClasses } from '@mui/material/Button'
import { colours } from './colours'

const fontWeight = {
  normal: 400,
  medium: 500,
  semiBold: 600,
  bold: 700
}

declare module '@mui/material/styles' {
  interface Theme {
    custom: {
      narrowWidth: string
    }
  }
  interface ThemeOptions {
    custom: {
      narrowWidth: string
    }
  }
}

export { ThemeProvider } from '@mui/material'

const baseTheme = createTheme({
  custom: {
    narrowWidth: '350px'
  },
  typography: {
    fontSize: 14,
    fontFamily: ['Inter', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    button: {
      fontSize: '1rem',
      textTransform: 'none'
    },
    h1: {
      fontWeight: 'bold',
      fontSize: '32px'
    },
    h2: {
      fontWeight: '600',
      fontSize: '24px'
    },
    h3: {
      fontWeight: '400',
      letterSpacing: '-1px',
      fontSize: '20px',
      '@media (max-width:600px)': {
        fontSize: '16px'
      }
    },
    body1: {
      fontSize: '14px'
    },
    body2: {
      fontSize: '12px'
    }
  },
  palette: {
    action: {
      disabledBackground: '', // so we can have custom disabled colours for buttons
      disabled: ''
    },
    text: {
      primary: colours.text.body,
      secondary: colours.text.secondary
    },
    primary: {
      main: colours.blue.b400,
      light: colours.blue.b200,
      dark: colours.blue.b500
    },
    error: {
      main: colours.red.r400,
      light: colours.red.r200,
      dark: colours.red.r500
    },
    secondary: {
      main: colours.neutral.n200,
      light: colours.background.bg100,
      dark: colours.neutral.n500
    },
    success: {
      main: colours.green.g400,
      light: colours.green.g200,
      dark: colours.green.g500
    },
    warning: {
      main: colours.yellow.y400,
      light: colours.yellow.y200,
      dark: colours.yellow.y500
    }
  }
})

/**
 * Splitting theme creation means we can use variables from baseTheme within the component overrides.
 */
export const theme = createTheme(baseTheme, {
  components: {
    MuiAvatar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: colours.background.bg200,
          color: colours.neutral.n200
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          transitionDuration: '200ms',
          '&&:hover': {
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            transitionDuration: '200ms'
          }
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          fontSize: '13px'
        },
        title: {
          color: colours.text.body,
          fontWeight: fontWeight.semiBold,
          fontSize: '14px'
        },
        subheader: {
          color: '#566370',
          fontSize: '13px'
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          fontSize: '13px',
          '&&:last-child': {
            paddingBottom: '15px'
          }
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          fontSize: '14px'
        }
      },
      variants: [
        {
          props: { severity: 'error' },
          style: {
            border: '1px solid ' + baseTheme.palette.error.main
          }
        },
        {
          props: { severity: 'warning' },
          style: {
            border: '1px solid ' + baseTheme.palette.warning.main
          }
        },
        {
          props: { severity: 'info' },
          style: {
            border: '1px solid ' + baseTheme.palette.info.main
          }
        },
        {
          props: { severity: 'success' },
          style: {
            border: '1px solid ' + baseTheme.palette.success.main
          }
        }
      ]
    },
    MuiButton: {
      variants: [
        {
          //This is required to make the text darker than the border on the outline button (SecondaryButton)
          props: { variant: 'outlined' },
          style: {
            color: '#39707D'
          }
        },
        {
          props: { variant: 'text' },
          style: {
            padding: 0,
            minWidth: 0
          }
        }
      ],
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: {
          fontSize: '14px',
          [`&.${buttonClasses.disabled}`]: {
            opacity: 0.3
          }
        },
        iconSizeSmall: {
          '& > *:first-child': {
            fontSize: '0.8125rem'
          }
        },
        iconSizeMedium: {
          '& > *:first-child': {
            fontSize: '0.9375rem'
          }
        },
        iconSizeLarge: {
          '& > *:first-child': {
            fontSize: '0.9375rem'
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
          paddingRight: '8px',
          paddingLeft: '9px'
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          height: '24px'
        },
        label: {
          paddingRight: 8,
          paddingLeft: 12
        }
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            backgroundColor: '#F6F7F8'
          }
        }
      ]
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          fontSize: '14px',
          textTransform: 'uppercase'
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '13px'
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '13px',
          color: '#69707d',
          marginBottom: '4px',
          '&.Mui-error': {
            color: '#69707d'
          },
          '&.Mui-focused': {
            color: '#69707d'
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginTop: '0'
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          height: '15px',
          marginLeft: 0,
          marginTop: 0,
          marginBottom: '3px',
          fontSize: '12px'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          padding: '0 !important',
          height: '32px',
          '&.Mui-focused': {
            boxShadow: '0 0 1px 1px #66afff',
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none'
            }
          }
        },
        input: {
          fontSize: '13px',
          padding: '5px',
          paddingLeft: '8px',
          color: '#69707d'
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: 'none'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          minHeight: '30px'
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          padding: '8px 16px',
          lineHeight: 1
        }
      }
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          backgroundColor: 'white'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '13px'
        },
        head: {
          fontWeight: 600
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '12px',
          backgroundColor: baseTheme.palette.secondary.dark
        },
        arrow: {
          color: baseTheme.palette.secondary.dark
        }
      }
    }
  }
})
