import React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Chip from '@mui/material/Chip'

type SelectOption = {
  label
  value
}

type Props = {
  value
  onChange
  options: SelectOption[]
}

export const MultiSelect = (props: Props) => {
  const { value, onChange, options } = props
  const handleChange = (event, newValues: SelectOption[]) => {
    onChange(newValues)
  }

  return (
    <Autocomplete
      multiple
      id="multi-select"
      value={value}
      freeSolo
      onChange={handleChange}
      options={options.filter((option) => !value.some((value) => value.label === option.label))}
      disableCloseOnSelect
      getOptionLabel={(option) => option.label}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => <Chip key={index} label={option.label} {...getTagProps({ index })} />)
      }
      renderInput={(params) => (
        <TextField {...params} variant="standard" label="" placeholder="Search..." size="small" />
      )}
      style={{ maxHeight: '350px', width: '300px', padding: '16px' }}
      sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
    />
  )
}
