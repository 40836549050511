import React from 'react'
import { ChildrenProps } from '../types'
import { Typography } from '@mui/material'

export function Heading1(props: ChildrenProps) {
  return (
    <Typography variant="h1" component="h1">
      {props.children}
    </Typography>
  )
}

export function Heading2(props: ChildrenProps) {
  return (
    <Typography variant="h2" component="h2">
      {props.children}
    </Typography>
  )
}

export function Heading3(props: ChildrenProps) {
  return (
    <Typography variant="h3" component="h3" noWrap>
      {props.children}
    </Typography>
  )
}
