import React from 'react'
import { ChildrenProps } from '../types'
import { Stack } from '@mui/material'
function FormFieldGroup(props: ChildrenProps) {
  return (
    <Stack direction="row" spacing={1}>
      {props.children}
    </Stack>
  )
}

export default FormFieldGroup
