import * as React from 'react'
import { Stack } from '@mui/material'
import { VerifiedIcon } from '../icons'

type Props = {
  title?: string
  message?: string
}

export default function ActionSuccess(props: Props) {
  const { title, message } = props

  return (
    <Stack alignItems="center" sx={{ marginBottom: 1 }}>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: '#E7F5F0',
          borderRadius: '50%',
          marginTop: 1,
          height: '50px',
          width: '50px',
          fontSize: '20px',
          color: '#09996A',
          marginBottom: 1
        }}>
        <VerifiedIcon fontSize="inherit" />
      </Stack>
      <Stack alignItems={'center'}>
        <b>{title}</b>
        <p style={{ textAlign: 'center' }}>{message}</p>
      </Stack>
    </Stack>
  )
}
