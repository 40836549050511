import React from 'react'
import { Stack } from '@mui/material'

import { ChildrenProps } from '../types'

export function ContentWrapper(props: ChildrenProps) {
  return (
    <Stack height={'100%'} overflow={'hidden'}>
      {props.children}
    </Stack>
  )
}
