import * as React from 'react'
import { TextField, FormControl, FormHelperText, FormLabel, Box, TextFieldProps } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useField } from 'formik'
import PropTypes from 'prop-types'

const FormFieldDatePicker = (props) => {
  const { label, onDateChange, shouldDisableDate, ...rest } = props
  const [field, meta, fieldHelper] = useField(props.name)
  const name = field.name
  const hasError = meta.touched && meta.error

  function handleChange(value) {
    fieldHelper.setValue(value)
    if (onDateChange) {
      onDateChange(value)
    }
  }

  return (
    <FormControl error={!!hasError} fullWidth>
      <FormLabel htmlFor={'fld-' + name}>{label}</FormLabel>
      <Box width={'200px'}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            {...field}
            {...rest}
            value={field.value}
            onChange={(value) => handleChange(value)}
            format={'DD/MM/YYYY'}
            shouldDisableDate={shouldDisableDate}
            className="custom-date-picker"
            sx={{
              '& .MuiInputAdornment-root': {
                padding: '16px'
              }
            }}
            renderInput={(params: TextFieldProps) => {
              return <TextField {...params} />
            }}></DatePicker>
        </LocalizationProvider>
      </Box>

      <FormHelperText>{hasError && meta.error}</FormHelperText>
    </FormControl>
  )
}

FormFieldDatePicker.propTypes = {
  label: PropTypes.string.isRequired,
  onDateChange: PropTypes.func,
  shouldDisableDate: PropTypes.func,
  name: PropTypes.string.isRequired,
  value: PropTypes.any
}

export default FormFieldDatePicker
