import React from 'react'
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from '@mui/material'
import { useField } from 'formik'

export type FormFieldRadioGroupProps = {
  name: string
  values: { label: string; value: string }[]
  label?: string | React.ReactElement
  disabled?: boolean
  noHelpText?: boolean
  row?: boolean
}

function FormFieldRadioGroup(props: FormFieldRadioGroupProps) {
  const { name, label, values, disabled, noHelpText, row, ...rest } = props
  const [field, meta, fieldHelper] = useField(props.name)
  const hasError = meta.touched && meta.error

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    fieldHelper.setValue((event.target as HTMLInputElement).value)
  }

  return (
    <FormControl error={!!hasError} disabled={disabled} fullWidth margin={'dense'}>
      <FormLabel id={'radio-' + name}>{label}</FormLabel>

      <RadioGroup aria-labelledby={'radio-' + name} value={field.value} onChange={onChange} {...field} row={row}>
        {values.map((item) => (
          <FormControlLabel
            sx={{ flexDirection: 'row', alignItems: 'center' }}
            control={<Radio value={item.value} disabled={disabled} disableRipple {...rest} />}
            label={item.label}
            key={item.value}
          />
        ))}
      </RadioGroup>

      {!noHelpText && <FormHelperText>{hasError && meta.error}</FormHelperText>}
    </FormControl>
  )
}

export default FormFieldRadioGroup
