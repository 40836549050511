import React from 'react'
import { useField } from 'formik'
import { FormControl, FormHelperText, FormLabel, MenuItem, Select, Stack } from '@mui/material'

export type SelectOption = {
  value: string
  label: string
  disabled?: boolean
  icon?: any
}

type FormFieldSelectProps = {
  name: string
  label: string
  disabled?: boolean
  placeholder: string
  value?: string
  options: SelectOption[]
  mandatory?: boolean
  icon?: any
  inline?: boolean
  fullWidth?: boolean
}

export default function FormFieldSelect(props: FormFieldSelectProps) {
  const { label, disabled, options, mandatory, placeholder, inline, fullWidth } = props
  const [field, meta] = useField(props.name)
  const name = field.name
  const hasError = meta.touched && meta.error

  return (
    <FormControl variant="outlined" disabled={disabled} error={!!hasError} fullWidth margin={'dense'}>
      {!inline && (
        <FormLabel htmlFor={'fld-' + name}>
          {label}
          {mandatory && '*'}
        </FormLabel>
      )}
      <div>
        <Stack direction="row" alignItems={'center'} spacing={1}>
          <Select
            data-testid={'fld-' + name}
            id={'fld-' + name}
            name={name}
            {...field}
            sx={{ width: fullWidth ? '100%' : 'auto' }}>
            <MenuItem key="null" value="null" disabled>
              {placeholder}
            </MenuItem>

            {options.map((option) => (
              <MenuItem key={option.value} value={option.value} data-cy={option.label}>
                {option.icon && (
                  <div
                    style={{
                      padding: '6px',
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                    {option.icon} <div style={{ padding: '6px' }}>{option.label}</div>
                  </div>
                )}
                {!option.icon && option.label}
              </MenuItem>
            ))}
          </Select>
          {inline && (
            <FormLabel htmlFor={'fld-' + name}>
              {label}
              {mandatory && '*'}
            </FormLabel>
          )}
        </Stack>
      </div>
      <FormHelperText>{hasError && meta.error}</FormHelperText>
    </FormControl>
  )
}
