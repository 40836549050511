import * as React from 'react'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { styled } from '@mui/material'

type Props = {
  steps: any
  currentStep: 0
}

const MyStepLabel = styled(StepLabel)(({ theme }) => ({
  '& .MuiStepLabel-label': {
    fontSize: '12px'
  },
  '& .MuiStepIcon-root': {
    fontSize: '12px',
    width: '20px',
    height: '20px'
  }
}))

export default function MyStepper(props: Props) {
  const { steps, currentStep } = props

  return (
    <Box>
      <Stepper activeStep={currentStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label}>
            <MyStepLabel>{label}</MyStepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}
