import React from 'react'
import { Box } from '@mui/material'
import { ChildrenProps } from '../types'

export function ContentHeader(props: ChildrenProps) {
  return (
    <Box
      px={2}
      height={'54px'}
      flexShrink={0}
      sx={{ backgroundColor: '#354a63', color: 'white' }}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}>
      {props.children}
    </Box>
  )
}
