import { ChildrenProps } from '../types'
import { Typography } from '@mui/material'
import React from 'react'

type Props = ChildrenProps & { align?: 'center' | 'inherit' | 'justify' | 'left' | 'right' }

export function Para(props: Props) {
  return (
    <Typography variant="body1" align={props.align ? props.align : 'left'}>
      {props.children}
    </Typography>
  )
}

export function ParaSmall(props: Props) {
  return (
    <Typography variant="body2" align={props.align ? props.align : 'left'} sx={{ color: 'text.secondary' }}>
      {props.children}
    </Typography>
  )
}
