import React from 'react'
import PropTypes from 'prop-types'
import Popover from '@mui/material/Popover'

const CustomPopover = ({ anchorEl, open, onClose, content, ...otherProps }) => {
  return (
    <Popover anchorEl={anchorEl} open={open} onClose={onClose} {...otherProps}>
      {content}
    </Popover>
  )
}

CustomPopover.propTypes = {
  anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  content: PropTypes.node.isRequired
}

export default CustomPopover
