import React, { useState, useEffect } from 'react'
import { Autocomplete, FormControl, FormHelperText, FormLabel, TextField } from '@mui/material'
import { useField } from 'formik'

type Option = {
  id: string
  label: string
}

type Props = {
  label: string
  disabled?: boolean
  placeholder: string
  options: Option[]
  mandatory?: boolean
  name: string
  onFieldChange?: (string: string) => void
  onValueChange?: (string: string) => void
}

export default function FormFieldSearch(props: Props) {
  const { label, disabled, placeholder, options, mandatory, name, onFieldChange, onValueChange } = props
  const [field, meta, formHelper] = useField(name)
  const hasError = meta.touched && meta.error
  const [value, setValue] = useState(null)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (field.value) {
      setValue(options.filter((opt) => opt.id === field.value)[0])
    }
  }, [])

  function onLocalSelect(_, newValue) {
    setValue(newValue)

    if (newValue) {
      formHelper.setValue(newValue.id)
    } else {
      formHelper.setValue('')
    }

    if (onValueChange) {
      onValueChange(newValue ? newValue.id : 'x')
    }
    setOpen(false)
  }

  function onBlur() {
    formHelper.setTouched(true)
    setOpen(false)
  }

  function onLocalFieldType(e) {
    formHelper.setTouched(true)

    if (onFieldChange) {
      onFieldChange(e.currentTarget.value || 'x')
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && e.target.value) {
      const option = options.filter((opt) => opt.label === e.target.value)[0]
      setValue(option)
      formHelper.setValue(option.id)
      if (onValueChange) {
        onValueChange(option ? option.id : 'x')
      }
      setOpen(false) // Close dropdown on enter
    }
  }

  return (
    <FormControl variant="standard" disabled={disabled} fullWidth margin={'dense'}>
      <FormLabel htmlFor={`fld-${field.name}`}>
        {label}
        {mandatory && '*'}
      </FormLabel>
      <Autocomplete
        ListboxProps={{ style: { maxHeight: '200px', overflow: 'auto' } }}
        disablePortal
        data-cy="combobox"
        options={options}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        {...field}
        onChange={onLocalSelect}
        value={value}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            disabled={disabled}
            placeholder={placeholder}
            onChange={onLocalFieldType}
            onKeyDown={handleKeyPress}
            onBlur={onBlur}
            error={!!hasError}
          />
        )}
      />
      <FormHelperText sx={{ color: 'red' }}>{hasError && meta.error}</FormHelperText>
    </FormControl>
  )
}
