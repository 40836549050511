import * as React from 'react'
import { CircularProgress, Stack } from '@mui/material'

type Props = {
  message?: string
}

export default function ActionLoading(props: Props) {
  const { message } = props

  return (
    <Stack alignItems="center" sx={{ marginBottom: 1 }}>
      <CircularProgress />
      <Stack alignItems={'center'}>{message}</Stack>
    </Stack>
  )
}
