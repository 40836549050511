// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./styles/colors.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./styles/spacing.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./styles/font.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body {\n    padding: 0;\n    margin: 0;\n    color: var(--color-text-body);\n    font-family: var(--font-family), Helvetica, Arial, sans-serif;\n/*    font-size: var(--font-size-m); */\n    line-height: 150%;\n    overflow-y: hidden;\n}\n\nh1 {\n    font-size: 28px;\n    line-height: 40px;\n    font-weight: var(--font-weight-bold);\n}\n\nh5 {\n    font-size: 14px;\n    line-height: 24px;\n    font-weight: var(--font-weight-semibold);\n}\n\n.MuiTableCell-root > button:not(:first-child) {\n    margin-left: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/global.css"],"names":[],"mappings":"AAIA;IACI,UAAU;IACV,SAAS;IACT,6BAA6B;IAC7B,6DAA6D;AACjE,sCAAsC;IAClC,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,oCAAoC;AACxC;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,wCAAwC;AAC5C;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":["@import './styles/colors.css';\n@import './styles/spacing.css';\n@import './styles/font.css';\n\nbody {\n    padding: 0;\n    margin: 0;\n    color: var(--color-text-body);\n    font-family: var(--font-family), Helvetica, Arial, sans-serif;\n/*    font-size: var(--font-size-m); */\n    line-height: 150%;\n    overflow-y: hidden;\n}\n\nh1 {\n    font-size: 28px;\n    line-height: 40px;\n    font-weight: var(--font-weight-bold);\n}\n\nh5 {\n    font-size: 14px;\n    line-height: 24px;\n    font-weight: var(--font-weight-semibold);\n}\n\n.MuiTableCell-root > button:not(:first-child) {\n    margin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
