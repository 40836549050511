import React from 'react'
import { AlertColor, Box } from '@mui/material'
import { ChildrenProps } from '../types'
import { colours } from '../colours'

type Props = ChildrenProps & {
  severity: AlertColor
  [key: string]: any
}

/**
 * This is a standard MUI box with a background colour corresponding to the theme colours for error, warning, info, success.
 * All other props are passed on to the Box
 * @param props
 * @constructor
 */
export function ColourBox(props: Props) {
  const { severity, children, ...rest } = props

  const sx = {
    default: {
      bgcolor: colours.neutral.n100,
      color: colours.neutral.n500
    },
    warning: {
      bgcolor: colours.yellow.y100,
      color: colours.yellow.y500
    },
    error: {
      bgcolor: colours.red.r100,
      color: colours.red.r500
    },
    success: {
      bgcolor: colours.green.g100,
      color: colours.green.g500
    },
    info: {
      bgcolor: colours.blue.b100,
      color: colours.blue.b500
    }
  }

  return (
    <Box {...rest} sx={{ backgroundColor: sx[severity].bgcolor, color: sx[severity].color }}>
      {children}
    </Box>
  )
}
