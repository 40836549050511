import React from 'react'
import { Box } from '@mui/material'
import { ChildrenProps } from '../types'

type Props = ChildrenProps & {
  padding?: number
}

export function Padding(props: Props) {
  const padding = props.padding ? props.padding : 2
  return <Box p={padding}>{props.children}</Box>
}
