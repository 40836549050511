import React from 'react'
import { Button, CircularProgress } from '@mui/material'

export enum ButtonType {
  Primary,
  Secondary,
  Danger = 3
}

export enum ButtonSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large'
}

export type ButtonProps = {
  children: string
  size?: ButtonSize
  icon?: React.ReactNode
  secondaryIcon?: React.ReactNode
  disabled?: boolean
  loading?: boolean
  isSubmit?: boolean
  fixedWidth?: boolean
  onClick?: () => void
}

type ButtonPropsInternal = ButtonProps & { buttonType: ButtonType }

function ButtonBase({ size = ButtonSize.Medium, ...props }: ButtonPropsInternal) {
  const { children, icon, secondaryIcon, disabled, isSubmit, buttonType, fixedWidth, loading, ...rest } = props

  const color = buttonType == ButtonType.Danger ? 'error' : buttonType == ButtonType.Primary ? 'primary' : 'secondary'
  const variant = buttonType == ButtonType.Secondary ? 'outlined' : 'contained'

  if (icon && secondaryIcon && !children) {
    throw new Error('If both primary and secondary icons are specified, the button must also have text.')
  }

  if (secondaryIcon && !children && !icon) {
    throw new Error('Invalid parameter combination. Use prop "icon" instead of "secondaryIcon".')
  }

  const sx = { minWidth: fixedWidth ? '150px' : 'auto' }

  return (
    <Button
      aria-label={props.children}
      color={color}
      variant={variant}
      disabled={disabled || loading}
      size={size}
      startIcon={loading && icon ? <CircularProgress color="inherit" size={16} /> : icon}
      endIcon={loading ? undefined : secondaryIcon}
      type={isSubmit ? 'submit' : 'button'}
      sx={sx}
      {...rest}>
      {loading ? <span>Loading...</span> : props.children}
    </Button>
  )
}

export default ButtonBase
