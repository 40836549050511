import React from 'react'
import { Box, CircularProgress } from '@mui/material'

export function Loading() {
  return (
    <Box width={'100%'} textAlign={'center'}>
      <CircularProgress />
    </Box>
  )
}
