import React, { useState } from 'react'
import { FormControl, FormHelperText, FormLabel, IconButton, InputAdornment, OutlinedInput } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useField } from 'formik'
import { FormFieldTextProps } from './FormFieldText'

type Props = FormFieldTextProps & {
  onFocus?: () => void
  onBlur?: () => void
}

export default function FormFieldPassword(props: Props) {
  const { label, placeholder, disabled, mandatory, onFocus, onBlur } = props
  const [showPassword, setShowPassword] = useState(false)
  const [field, meta] = useField(props.name)
  const hasError = meta.touched && meta.error

  function handleClickShowPassword() {
    setShowPassword(!showPassword)
  }

  return (
    <FormControl variant="standard" error={!!hasError} disabled={disabled} fullWidth margin={'dense'}>
      <FormLabel htmlFor={'fld-' + field.name}>
        {label}
        {mandatory && '*'}
      </FormLabel>
      <OutlinedInput
        type={showPassword ? 'text' : 'password'}
        id={'fld-' + field.name}
        placeholder={placeholder}
        error={!!hasError}
        onFocus={onFocus}
        onBlurCapture={onBlur}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
              tabIndex={-1}>
              {showPassword ? <Visibility fontSize={'small'} /> : <VisibilityOff fontSize={'small'} />}
            </IconButton>
          </InputAdornment>
        }
        {...field}
      />
      <FormHelperText>{hasError && meta.error}</FormHelperText>
    </FormControl>
  )
}
