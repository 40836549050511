import React from 'react'
import { Box, Button, SvgIcon, Typography } from '@mui/material'

type Props = {
  variant?: 'default' | 'icon'
}

function SharedIconSVG(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="18" height="18" rx="9" fill="#004493" />
        <path
          d="M12.3617 7.80973C12.0692 8.10223 11.5817 8.10223 11.2892 7.80973L7.93672 4.46473L2.64922 9.74473L2.15422 9.27223C1.27672 8.39473 1.27672 6.96973 2.15422 6.09223L5.33422 2.91223C6.21172 2.03473 7.63672 2.03473 8.51422 2.91223L12.3617 6.75223C12.6542 7.04473 12.6542 7.51723 12.3617 7.80973ZM12.8867 6.21973C13.4717 6.80473 13.4717 7.75723 12.8867 8.34223C11.9342 9.29473 10.9292 8.50723 10.7642 8.34223L7.94422 5.52223L3.76672 9.69973C3.47422 9.99223 3.47422 10.4647 3.76672 10.7572C4.05922 11.0497 4.53172 11.0497 4.83172 10.7572L8.29672 7.29223L8.82922 7.82473L5.36422 11.2897C5.07172 11.5822 5.07172 12.0547 5.36422 12.3472C5.65672 12.6397 6.12922 12.6397 6.42922 12.3472L9.89422 8.88223L10.4267 9.41473L6.96172 12.8797C6.66922 13.1722 6.66922 13.6447 6.96172 13.9372C7.25422 14.2297 7.72672 14.2297 8.01922 13.9372L11.4842 10.4722L12.0167 11.0047L8.55172 14.4697C8.25922 14.7622 8.25922 15.2347 8.55172 15.5272C8.84422 15.8197 9.31672 15.8197 9.60922 15.5272L15.8492 9.27223C16.7267 8.39473 16.7267 6.96973 15.8492 6.09223L12.6692 2.91223C11.8067 2.04973 10.4117 2.03473 9.53422 2.86723L12.8867 6.21973Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  )
}

function SharedIcon() {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginRight: '5px',
        backgroundColor: '#004493',
        borderRadius: '50%',
        padding: '3px'
      }}>
      <SharedIconSVG sx={{ color: 'white' }} />
    </Box>
  )
}

export function SharedIndicator(props: Props) {
  return props.variant === 'icon' ? (
    <SharedIcon />
  ) : (
    <Button
      variant="contained"
      sx={{
        backgroundColor: '#4285F4',
        color: 'white',
        padding: '4px 10px 4px 4px',
        borderRadius: '30px',
        display: 'flex',
        alignItems: 'center',
        textTransform: 'none',
        zoom: 0.9
      }}>
      <SharedIcon />
      <Typography variant="button" sx={{ fontSize: '10px' }}>
        Shared Records
      </Typography>
    </Button>
  )
}
