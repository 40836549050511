import React, { ReactNode } from 'react'
import { Tooltip } from '@mui/material'
import { ChildrenProps } from '../types'

type Props = ChildrenProps & {
  title: ReactNode
}

export function ToolTip(props: Props) {
  return (
    <Tooltip arrow placement="top" title={props.title} disableInteractive>
      {props.children}
    </Tooltip>
  )
}
