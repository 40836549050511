import React from 'react'
import { Box } from '@mui/material'
import { colours } from '../colours'

type Props = {
  text: string
  variant?: 'default' | 'warning' | 'error' | 'success' | 'info'
}

export function Label({ text, variant = 'default' }: Props) {
  const sx = {
    default: {
      bgcolor: colours.neutral.n100,
      color: colours.neutral.n500
    },
    warning: {
      bgcolor: colours.yellow.y100,
      color: colours.yellow.y500
    },
    error: {
      bgcolor: colours.red.r100,
      color: colours.red.r500
    },
    success: {
      bgcolor: colours.green.g100,
      color: colours.green.g500
    },
    info: {
      bgcolor: colours.blue.b100,
      color: colours.blue.b500
    }
  }

  return (
    <Box px={1} py={0.5} borderRadius={1} textTransform={'uppercase'} fontSize={'10px'} lineHeight={1} sx={sx[variant]}>
      {text}
    </Box>
  )
}
