import React from 'react'
import { ChildrenProps } from '../types'
import { Alert, AlertColor, AlertTitle } from '@mui/material'

export type AlertProps = ChildrenProps & {
  title?: string
}

type AlertPropsInternal = AlertProps & {
  severity: AlertColor
}

export function AlertError(props: AlertProps) {
  return <AlertBase severity={'error'} {...props} />
}

export function AlertWarning(props: AlertProps) {
  return <AlertBase severity={'warning'} {...props} />
}

export function AlertInfo(props: AlertProps) {
  return <AlertBase severity={'info'} {...props} />
}

export function AlertSuccess(props: AlertProps) {
  return <AlertBase severity={'success'} {...props} />
}

function AlertBase(props: AlertPropsInternal) {
  const { title, severity, children } = props
  return (
    <Alert severity={severity}>
      {title && <AlertTitle>{title}</AlertTitle>}
      {children}
    </Alert>
  )
}
