import React from 'react'
import { Tooltip } from '@mui/material'
import { ChildrenProps } from '../types'

type Props = ChildrenProps & {
  title: string
  open: boolean
}

export function ControlledToolTip(props: Props) {
  const { open } = props

  return (
    <Tooltip arrow placement="top" title={props.title} open={open}>
      {props.children}
    </Tooltip>
  )
}
