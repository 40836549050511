import React from 'react'
import { Stack } from '@mui/material'

import { ChildrenProps } from '../types'
import { theme } from '../theme'

type Props = ChildrenProps & { variant?: 'white' | 'grey' }

export function ContentNonScrolling(props: Props) {
  const bgColour = props.variant === 'grey' ? theme.palette.grey['200'] : 'white'
  return (
    <Stack overflow={'hidden'} padding={2} bgcolor={bgColour}>
      {props.children}
    </Stack>
  )
}
