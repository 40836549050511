import React from 'react'

import { IconButton } from '@mui/material'

type Props = {
  icon: React.ReactNode
  onClick?: () => void
}

export function IconButtonPrimary(props: Props) {
  const { icon, ...rest } = props
  return (
    <IconButton
      {...rest}
      sx={{
        color: 'white',
        borderRadius: '4px',
        backgroundColor: 'primary.main',
        '&:hover': {
          backgroundColor: 'primary.main',
          filter: 'brightness(0.95)',
          transition: 'all 0.5s ease'
        }
      }}>
      {props.icon}
    </IconButton>
  )
}
