import React from 'react'
import { Box } from '@mui/material'

export type AvatarDetails = {
  initials: string
  avatar?: string
  avatarColour: string
}

type Props = {
  avatarDetails?: AvatarDetails
  size: 'small' | 'medium'
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
}

export function UserAvatar(props: Props) {
  const dimensions = props.size === 'small' ? '30px' : '40px'

  return (
    <>
      {!props.avatarDetails && (
        <Box
          data-testid="user-avatar-loading"
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          sx={{
            borderRadius: '50%',
            width: dimensions,
            height: dimensions,
            backgroundColor: 'lightgray',
            color: 'white'
          }}>
          <div> </div>
        </Box>
      )}

      {props.avatarDetails && !props.avatarDetails.avatar && (
        <Box
          data-testid="user-avatar"
          onClick={props.onClick}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          sx={{
            borderRadius: '50%',
            width: dimensions,
            height: dimensions,
            backgroundColor: `#${props.avatarDetails.avatarColour}`,
            color: 'white',
            cursor: 'pointer'
          }}>
          <div>{props.avatarDetails.initials}</div>
        </Box>
      )}

      {props.avatarDetails && props.avatarDetails.avatar && (
        <Box
          data-testid="user-avatar"
          onClick={props.onClick}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          sx={{
            borderRadius: '50%',
            width: dimensions,
            height: dimensions,
            backgroundImage: `url(${props.avatarDetails.avatar})`,
            backgroundColor: 'transparent',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            cursor: 'pointer'
          }}>
          <div> </div>
        </Box>
      )}
    </>
  )
}
