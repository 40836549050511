/* eslint-disable */
import React from 'react'
import { SvgIcon } from '@mui/material'

export default function PartnerIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        d="M12.222 19.8559C12.042 20.0359 11.722 20.0659 11.512 19.8559C11.332 19.6759 11.302 19.3559 11.512 19.1459L14.902 15.7559L13.492 14.3459L10.102 17.7359C9.91198 17.9359 9.59198 17.9259 9.39198 17.7359C9.18198 17.5259 9.21198 17.2059 9.39198 17.0259L12.782 13.6359L11.372 12.2259L7.98198 15.6159C7.80198 15.7959 7.48198 15.8259 7.27198 15.6159C7.08198 15.4259 7.08198 15.1059 7.27198 14.9059L10.662 11.5159L9.24198 10.1059L5.85198 13.4959C5.67198 13.6759 5.35198 13.7059 5.14198 13.4959C4.95198 13.2959 4.95198 12.9859 5.14198 12.7859L9.52198 8.40586L11.392 10.2659C12.342 11.2159 13.982 11.2059 14.932 10.2659C15.912 9.28586 15.912 7.70586 14.932 6.72586L13.072 4.86586L13.352 4.58586C14.132 3.80586 15.402 3.80586 16.182 4.58586L20.422 8.82586C21.202 9.60586 21.202 10.8759 20.422 11.6559L12.222 19.8559ZM21.832 13.0759C23.392 11.5159 23.392 8.98586 21.832 7.41586L17.592 3.17586C16.032 1.61586 13.502 1.61586 11.932 3.17586L11.652 3.45586L11.372 3.17586C9.81198 1.61586 7.28198 1.61586 5.71198 3.17586L2.17198 6.71586C0.751984 8.13586 0.621984 10.3459 1.77198 11.9059L3.22198 10.4559C2.83198 9.70586 2.96198 8.75586 3.59198 8.12586L7.13198 4.58586C7.91198 3.80586 9.18198 3.80586 9.96198 4.58586L13.522 8.14586C13.702 8.32586 13.732 8.64586 13.522 8.85586C13.312 9.06586 12.992 9.03586 12.812 8.85586L9.52198 5.57586L3.72198 11.3659C2.74198 12.3359 2.74198 13.9259 3.72198 14.9059C4.11198 15.2959 4.61198 15.5359 5.14198 15.6059C5.21198 16.1259 5.44198 16.6259 5.84198 17.0259C6.24198 17.4259 6.74198 17.6559 7.26198 17.7259C7.33198 18.2459 7.56198 18.7459 7.96198 19.1459C8.36198 19.5459 8.86198 19.7759 9.38198 19.8459C9.45198 20.3859 9.69198 20.8759 10.082 21.2659C10.552 21.7359 11.182 21.9959 11.852 21.9959C12.522 21.9959 13.152 21.7359 13.622 21.2659L21.832 13.0759Z"
        fill={props.color}
      />
    </SvgIcon>
  )
}
