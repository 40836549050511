import React from 'react'
import { Radio, FormControl, FormControlLabel, FormHelperText, RadioGroup } from '@mui/material'
import { useField } from 'formik'
import { FlexRow } from '../layout'

export type FormFieldCheckboxProps = {
  name: string
  label: string | React.ReactElement
  disabled?: boolean
  noHelpText?: boolean
}

function FormFieldRadio(props: FormFieldCheckboxProps) {
  const { label, disabled, noHelpText, ...rest } = props
  const [field, meta, fieldHelper] = useField(props.name)
  const hasError = meta.touched && meta.error

  function onChange(value) {
    if (value === '1' || value === true) {
      value = '0'
      return fieldHelper.setValue(value)
    }
    if (value === '0' || value === false) {
      value = '1'
      return fieldHelper.setValue(value)
    }
  }

  return (
    <FormControl error={!!hasError} disabled={disabled} fullWidth margin={'dense'}>
      <FormControlLabel
        sx={{ flexDirection: 'column-reverse', alignItems: 'flex-start', marginLeft: '4px', color: '#69707d' }}
        control={
          <RadioGroup {...field} value={field.value} onChange={() => onChange(field.value)} {...rest}>
            <FlexRow justifyContent={'space-evenly'}>
              <FormControlLabel
                disabled={disabled}
                checked={field.value === '1' || field.value === true}
                value={true || '1'}
                control={<Radio />}
                label="Positive"
              />
              <FormControlLabel
                disabled={disabled}
                checked={field.value === '0' || field.value === false}
                value={false || '0'}
                control={<Radio />}
                label="Negative"
              />
            </FlexRow>
          </RadioGroup>
        }
        label={label}
      />
      {!noHelpText && <FormHelperText>{hasError && meta.error}</FormHelperText>}
    </FormControl>
  )
}

export default FormFieldRadio
