import React from 'react'

import { Button } from '@mui/material'

type Props = {
  disabled?: boolean
  onClick: () => void
  colour?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
  children: any
}

export function TextButton(props: Props) {
  const { disabled, onClick, children, colour } = props
  return (
    <Button color={colour ? colour : 'primary'} variant="text" disabled={disabled} onClick={onClick}>
      {children}
    </Button>
  )
}
