export const colours = {
  text: {
    title: '#1a1c21',
    body: '#213854',
    secondary: '#a6afbb',
    disabled: '#abb4c4'
  },
  background: {
    white: 'white',
    bg100: '#f6f7f8',
    bg200: '#e4e7eb',
    bg300: '#c2c8d0'
  },
  blue: {
    b100: '#e5f2ff',
    b200: '#99caff',
    b300: '#66afff',
    b400: '#007aff',
    b500: '#0863c6',
    b600: '#004a97',
    b700: '#002649'
  },
  yellow: {
    y50: '#fef1d9',
    y100: '#fde4b3',
    y200: '#fcdb9a',
    y300: '#f9c04f',
    y400: '#f7a503',
    y500: '#ad7302',
    y600: '#634201',
    y700: '#191000'
  },
  red: {
    r50: '#fef5f5',
    r100: '#fdebec',
    r200: '#f7afb5',
    r300: '#f3878f',
    r400: '#eb3745',
    r500: '#d41927',
    r600: '#b10f1b',
    r700: '#8b1019'
  },
  green: {
    g100: '#e7f5f0',
    g200: '#9dd6c3',
    g300: '#54b897',
    g400: '#0b996a',
    g500: '#086b4a',
    g600: '#043d2a',
    g700: '#010f0b'
  },
  neutral: {
    n100: '#e9ebee',
    n200: '#a6afbb',
    n300: '#7a8898',
    n400: '#213854',
    n500: '#072448',
    n600: '#051932',
    n700: '#030e1d'
  },
  border: {
    border100: '#edf0f2',
    border200: '#e4e7eb',
    border300: '#bac7d5'
  }
}

export default colours
