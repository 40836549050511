import React from 'react'
import { SvgIcon } from '@mui/material'

export default function IAuditorIcon(props) {
  return (
    <SvgIcon viewBox={'0 0 1417.32 1417.32'} {...props}>
      <path
        style={{ fill: '#CECECE' }}
        d="M631.73,1313.35c33.57,33.57,87.64,35.43,124.01,5.59l0.93-0.93c1.86-1.86,3.73-2.8,5.59-4.66
      c1.86-1.86,3.73-3.73,4.66-5.59l541.71-541.71c1.86-1.86,3.73-2.8,5.59-4.66c36.36-36.36,36.36-94.17,0-130.53
      c-34.5-34.5-89.51-35.43-125.87-4.66l0,0L625.2,1189.34l-59.67,59.67L631.73,1313.35z"></path>
      <path
        style={{ fill: '#5C5C5C' }}
        d="M369.73,1051.35l197.66,197.66l130.53-130.53L500.27,920.82c-36.36-36.36-94.17-36.36-130.53,0
      C333.37,956.25,333.37,1014.99,369.73,1051.35z"></path>
      <path
        style={{ fill: '#D1D1D1' }}
        d="M786.5,103.13c-33.57-33.57-87.64-35.43-124.01-5.59l-0.93,0.93c-1.86,1.86-3.73,2.8-5.59,4.66
      c-1.86,1.86-3.73,3.73-4.66,5.59L109.6,650.43c-1.86,1.86-3.73,2.8-5.59,4.66c-36.36,36.36-36.36,94.17,0,130.53
      c34.5,34.5,89.51,35.43,125.87,4.66l0,0l563.15-562.22l59.67-59.67L786.5,103.13z"></path>
      <path
        style={{ fill: '#B0B0B0' }}
        d="M1049.43,366.05L851.77,168.39L721.24,298.92L918.9,496.59c36.36,36.36,94.17,36.36,130.53,0
      C1085.8,460.22,1085.8,401.48,1049.43,366.05z"></path>
    </SvgIcon>
  )
}
