import React from 'react'
import { Stack } from '@mui/material'

import { ChildrenProps } from '../types'

type Props = ChildrenProps & { padding?: string }

export function ContentScrollingBlue(props: Props) {
  return (
    <Stack padding={props.padding || 2} bgcolor={'#354a63'}>
      {props.children}
    </Stack>
  )
}
